<template>
    <div class="grid grid-col-1 lg:grid-cols-8">
    <div class="p-6 md:col-span-3">
      <Timer :streams="streams" />
      <div class="bg-green text-white mx-5 p-6 mt-12">
        <div class="text-center">
          <h2>Zu den technologischen Themen</h2>
          <p>
            Wenn Sie zu dem technologischen Strang wechseln möchten, klicken Sie den Button.
          </p>
          <button
            class="px-4 py-2 bg-white text-green rounded-full mt-6"
            @click="changeRoute('technologie')"
          >
            Strang technologische Themen
          </button>
        </div>
      </div>
    </div>
    <div class="col-span-8 p-6 lg:col-span-5">
      <h2 class="font-bold text-xl pb-5">Strategischer Strang</h2>
      <div class="video-container-wrapper">
        <div class="video-container">
          <!-- <iframe
            width="500"
            src="https://www.youtube.com/embed/Og2If8Q8yw0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import Timer from "../components/Timer";
import streams from "../assets/strategie.json";

export default {
  name: "Strategie",
  components: { Timer },
  created() {
    console.log("created strategie");
    this.uid = this.getCurrentUser();
    const dataObject = {
      type: "PING",
      created: new Date(),
      uid: this.uid,
      pre: "strategie",
      post: "strategie",
    };
    firebase.functions().httpsCallable("sunny")(dataObject);
  },
  mounted() {
    console.log("hier");
    console.log(this.uid);
    setInterval(() => {
      const dataObject = {
        type: "PING",
        created: new Date(),
        uid: this.uid,
        pre: "strategie",
        post: "strategie",
      };
      firebase.functions().httpsCallable("sunny")(dataObject);
    }, 600000);
  },
  methods: {
    getCurrentUser() {
      const user = firebase.auth().currentUser;
      return user.uid;
    },
    changeRoute(nextRoute) {
      const dataObject = {
        type: "CHANGE",
        created: new Date(),
        uid: this.uid,
        pre: "strategie",
        post: "technologie",
      };
      firebase.functions().httpsCallable("sunny")(dataObject);
      this.$router.push(`/${nextRoute}`);
    },
  },
  data() {
    return {
      uid: "",
      streams,
    };
  },
};
</script>

<style>
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-container-wrapper {
  max-width: 100%;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
